import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import Seo from '../components/Seo';
import PageLayout from '../components/PageLayout';

const pageName = '404 Page Not Found';

const PageNotFound: FC<PageProps> = ({ path }) => {
    // console.log(params);
    return (
        <PageLayout path={path||""} >
            <Seo title={pageName} />
            <h1>{pageName}</h1>

        </PageLayout>
    )
};

export default PageNotFound;
